import { useEffect, useState } from "react"
import Separator from "../../../assets/pictures/septor.png"
import TestBack from "../../../assets/pictures/test-back.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Kathleen from "../../../assets/pictures/kathleen.png"
import Kevin from "../../../assets/pictures/kevin.jpg"
import Loreen from "../../../assets/pictures/loreen.jpg"
import Vincent from "../../../assets/pictures/vincent.jpeg"
import Nathalie from "../../../assets/pictures/nathalie.jpg"

import { Carousel } from "@material-tailwind/react"

export default function Testimonials() {
    const [currentTestimonial, setCurrentTestimonial] = useState(1)

    useEffect(() => {
        const timer = setInterval(() => {
            if (currentTestimonial === 3)
                setCurrentTestimonial(1)
            else
                setCurrentTestimonial(currentTestimonial + 1)
        }, 5000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="flex flex-col lg:flex-row py-12 px-4 lg:py-12 gap-4">
            <div className="flex flex-col gap-4 lg:w-1/2 justify-center items-center">
                <span
                    className="text-[30px] mb-2 text-black text-center font-bold"
                >
                    Nos témoignages
                </span>
                <img src={Separator} />
            </div>
            <div 
                className="flex flex-col gap-4 lg:w-1/2 rounded-l-2xl relative h-[500px] justify-center"
                style={{
                    backgroundImage: `url(${TestBack})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                }}    
            >
                <Carousel
                    loop={true}
                    placeholder=""
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    className="h-72 lg:-ml-12"
                    autoplay={true}
                    autoplayDelay={10000}
                    prevArrow={({ handlePrev }) => (
                        <></>
                    )}
                    nextArrow={({ handleNext }) => (
                        <></>
                    )}
                    navigation={({ setActiveIndex, activeIndex, length }) => (
                        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                          {new Array(length).fill("").map((_, i) => (
                            <span
                              key={i}
                              className={`block h-3 w-3 rounded-full cursor-pointer transition-all content-[''] ${
                                activeIndex === i ? "bg-[#c12a26]" : "bg-black/50"
                              }`}
                              onClick={() => setActiveIndex(i)}
                            />
                          ))}
                        </div>
                    )}
                >
                    <div className="carousel-item w-full flex items-center justify-center lg:items-start lg:justify-start gap-4">
                        <div className="w-32 aspect-square bg-red-500">
                            <img src={Kevin} className="w-full h-full object-top object-cover" />
                        </div>
                        <div className="flex justify-start items-start flex-col gap-4">
                            <FontAwesomeIcon icon="quote-left" className="text-3xl text-[#c12a26]" />
                            <span className="text-xl font-bold text-black">Kevin Balaguer</span>
                            <span>
                                Ma meilleure petite soeur, <br />
                                Toutes ces années de falsification de factures <br />
                                et ces heures passées à faire logo et drapeau <br />
                                n'ont fait qu'augmenter mon amour et respect pour elle. <br />
                                Je suis même prêt à lui laisser mes cadeaux !
                            </span>
                        </div>
                    </div>
                    <div className="carousel-item w-full flex items-center justify-center lg:items-start lg:justify-start gap-4">
                        <div className="w-32 aspect-square bg-red-500">
                            <img src={Loreen} className="w-full h-full object-top object-cover" />
                        </div>
                        <div className="flex justify-start items-start flex-col gap-4">
                            <FontAwesomeIcon icon="quote-left" className="text-3xl text-[#c12a26]" />
                            <span className="text-xl font-bold text-black">Loreen Balaguer</span>
                            <span>
                                Après tous les livres qu'elle m'a fait découvrir <br />
                                et qui ont changé ma vie, <br />
                                je ne peux que lui souhaiter d'avoir tous ses cadeaux à Noël ! <br />
                                Elle est la meilleure ! <br />
                            </span>
                        </div>
                    </div>
                    <div className="carousel-item w-full flex items-center justify-center lg:items-start lg:justify-start gap-4">
                        <div className="w-32 aspect-square bg-red-500">
                            <img src={Kathleen} className="w-full h-full object-top object-cover" />
                        </div>
                        <div className="flex justify-start items-start flex-col gap-4">
                            <FontAwesomeIcon icon="quote-left" className="text-3xl text-[#c12a26]" />
                            <span className="text-xl font-bold text-black">Kathleen Balaguer</span>
                            <span>
                                La meilleure grande soeur dont on puisse rêver ! <br />
                                Elle a toujours été là pour moi, <br />
                                Même quand je n'avais pas de train pour rentrer chez moi. <br />
                                Elle était là pour héberger la SDF que j'étais.<br />
                                Et elle m'a même offert le mcdo !
                            </span>
                        </div>
                    </div>
                    <div className="carousel-item w-full flex items-center justify-center lg:items-start lg:justify-start gap-4">
                        <div className="w-32 aspect-square bg-red-500">
                            <img src={Vincent} className="w-full h-full object-top object-cover" />
                        </div>
                        <div className="flex justify-start items-start flex-col gap-4">
                            <FontAwesomeIcon icon="quote-left" className="text-3xl text-[#c12a26]" />
                            <span className="text-xl font-bold text-black">Vincent Balaguer</span>
                            <span>
                                Eileen ne peut qu'avoir de superbes cadeaux cette année. <br />
                                Après toutes les factures qu'elle a modifiées pour moi,<br />
                                même si j'ai fini en prison, <br />
                                je suis sûr qu'elle a fait un excellent travail.<br />
                                Même si je ne sais jamais ce qu'elle va avoir, <br />
                                je suis sûr qu'elle mérite les meilleurs cadeaux.
                            </span>
                        </div>
                    </div>
                    <div className="carousel-item w-full flex items-center justify-center lg:items-start lg:justify-start gap-4">
                        <div className="w-32 aspect-square bg-red-500">
                            <img src={Nathalie} className="w-full h-full object-top object-cover" />
                        </div>
                        <div className="flex justify-start items-start flex-col gap-4">
                            <FontAwesomeIcon icon="quote-left" className="text-3xl text-[#c12a26]" />
                            <span className="text-xl font-bold text-black">Nathalie Balaguer</span>
                            <span>
                                Dévouée pour mon activité de scrapbooking, <br />
                                Elle a réalisé le logo dont j'aurais toujours rêvé. <br />
                                Sans même savoir ce que je voulais, <br />
                                Eileen est si incroyable <br />
                                Qu'elle mérite tous les cadeaux du Kikounet, <br />
                                Mon fils préféré.
                            </span>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}