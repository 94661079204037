import FallingObjects from "../../components/FallingSnow";
import Cards from "./components/Cards";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Listing from "./components/Listing";
import Navbar from "./components/Navbar";
import News from "./components/News";
import Section from "./components/Section";
import SpecialDeals from "./components/SpecialDeals";
import Testimonials from "./components/Testimonials";

export default function Home() {
    return (
        <> 
            <FallingObjects />
            <Navbar />
            <Hero />
            <Section />
            <Listing />
            <Cards />
            <Testimonials />
            <SpecialDeals />
            <News />
            <Footer />
        </>
    )
}