import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import Credits from "./Credits/Credits";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/credits" element={<Credits />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
