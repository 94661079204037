import { Carousel } from "@material-tailwind/react"
import Separator from "../../../assets/pictures/septor.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import AmozPoignetAccident from "../../../assets/pictures/news/amoz_poignet.jpg"
import Warump from "../../../assets/pictures/news/warmup.jpg"
import DegatDesEaux2 from "../../../assets/pictures/news/doucheDegatDesEaux.png"
import DegatDesEaux1 from "../../../assets/pictures/news/fuiteDeau.jpg"
import VoitureFeu from "../../../assets/pictures/news/voitureFeu.webp"
import RetourQuebequois from "../../../assets/pictures/news/champagne.webp"
import Ingenieur from "../../../assets/pictures/news/ingenieur.jpg"
import Permis1Maxime from "../../../assets/pictures/news/chuteA.webp"
import Impots from "../../../assets/pictures/news/impots.webp"
import Locaux from "../../../assets/pictures/news/locaux.jpg"
import AudiA3 from "../../../assets/pictures/news/audiA3.webp"
import PuyDuFou from "../../../assets/pictures/news/puyDuFou.webp"
import MotardContent from "../../../assets/pictures/news/motardContent.jpg"
import Chloe from "../../../assets/pictures/news/chloe.jpg"
import MaximePayant from "../../../assets/pictures/news/MaximePayant.png"
import Eileen from "../../../assets/pictures/news/ingenieurBis.jpg"
import Thurins from "../../../assets/pictures/news/thurins.png"
import Appartement from "../../../assets/pictures/news/appartement.png"
import RiveDegier from "../../../assets/pictures/news/riveDeGier.jpg"

const Card = ({
    card
} : {
    card: {
        title: string,
        description: string,
        picture: string,
        date: string
    }
}) => {
    return (
        <div className="h-96 text-base flex flex-col justify-center items-center w-full mb-12">
            <div className="aspect-square h-80 relative bg-[#ccc]/10 flex items-center justify-center w-full">
                <img src={card.picture} className="w-full h-full object-cover object-center" />
            </div>
            <div className="flex flex-col justify-center items-center border w-full">
                <div className="w-2/3 py-2 flex justify-center items-center text-center border-b mb-2 font-light">
                    <FontAwesomeIcon icon="calendar" className="h-3 mr-2 -mt-0.5" /> {card.date}
                </div>
                <span className="text-[#c12a26] font-medium">{card.title}</span>
                <span className="font-light pb-2 px-2 text-center">{card.description}</span>
            </div>
        </div>
    )
}

const carrouselItems = [
    {
        products: [
            {
                title: "Dégats des eaux n°1", 
                description: "Un concept original la pluie dans la cuisine",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
                picture: DegatDesEaux1,
                date: "Janvier 2024"
            },
            {
                title: "Prise des locaux", 
                description: "Notre aventure a démarrée au 160 avenue Jean Jaurès",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
                picture: Locaux,
                date: "Février 2024"
            },
            {
                title: "Voiture d'Amoz qui flambe", 
                description: "Et non, ce n'est ni une blague, ni une référence à Johnny",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
                picture: VoitureFeu,
                date: "Mars 2024"
            },
            {
                title: "Retour du québécois", 
                description: "Fini les caribous et les tabernak, il est de retour",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: RetourQuebequois,
                date: "Mai 2024"
            },
            {
                title: "Diplôme obtenu !", 
                description: "Eh oui, l'égo de l'ingénieur est encore plus grand",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
                picture: Ingenieur,
                date: "Juin 2024"
            },
            {
                title: "Essai 1 permis Maxime", 
                description: "Le premier d'une longue série...",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
                picture: Permis1Maxime,
                date: "Juin 2024"
            },
        ]
    },
    {
        products: [
            {
                title: "Bilan de la société", 
                description: "Et les impôts aussi nous ont félicité",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: Impots,
                date: "Juillet 2024"
            },
            {
                title: "Nouvelle Audi dans la famille", 
                description: "18 000km autorisés, 60 000 déjà réalisés",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: AudiA3,
                date: "Juillet 2024"
            },
            {
                title: "Voyage au Puy du Fou", 
                description: "Avec nos amis Lucas et Céline",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: PuyDuFou,
                date: "Juillet 2024"
            },
            {
                title: "Essai 2 permis Maxime", 
                description: "Toujours de l'espoir...",
                // pictkure: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: Permis1Maxime,
                date: "Août 2024"
            },
            {
                title: "Permis obtenu !", 
                description: "Amoz a obtenu son permis ! Mais presque aussitôt...",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: MotardContent,
                date: "Août 2024"
            },
            {
                title: "Premier CDI signé", 
                description: "Premier CDI signé par un membre de l'équipe !",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
                picture: Chloe,
                date: "Septembre 2024"
            },
        ]
    },
    {
        products: [
            {
                title: "CDI pour l'ingénieur", 
                description: "Et un CDI de signé, un !",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
                picture: Eileen,
                date: "Septembre 2024"
            },
            {
                title: "Permis obtenu ! (bis)", 
                description: "Du premier coup, ou presque...",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
                picture: MotardContent,
                date: "Septembre 2024"
            },
            {
                title: "Amoz fait un bisou à une voiture", 
                description: "Et oui, il a osé, et son poignet s'en souvient",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
                picture: AmozPoignetAccident,
                date: "Septembre 2024"
            },
            {
                title: "Vacances en Italie gratuit", 
                description: "+ week-end avec Amoz et son poignet",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
                picture: Thurins,
                date: "Septembre 2024"
            },
            {
                title: "Changement d'appartement", 
                description: "Et oui, parce qu'un ingénieur ça déménage",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: Appartement,
                date: "Septembre 2024"
            },
            {
                title: "Passerelle moto de Quentin", 
                description: "Du premier coup ! (enfin !)",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: MotardContent,
                date: "Septembre 2024"
            },
        ]
    },
    {
        products: [
            {
                title: "Dégats des eaux n°2", 
                description: "Parce que dans la cuisine ça ne suffisait pas, il nous fallait aussi la salle de bain",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
                picture: DegatDesEaux2,
                date: "Septembre 2024"
            },
            {
                title: "Victoire au concours innovation Epitech", 
                description: "Parce qu'il n'y a pas que l'ingénieur qui réfléchit parfois",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
                picture: Warump,
                date: "Octobre 2024"
            },
            {
                title: "CDI de Maxime", 
                description: "Parce qu'on n'avait pas assez d'un, il en fallait un deuxième",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
                picture: MaximePayant,
                date: "Octobre 2024"
            },
            {
                title: "Essai 3 permis Maxime", 
                description: "Et un, et deux, et trois zéro !",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
                picture: Permis1Maxime,
                date: "Novembre 2024"
            },
            {
                title: "Innondation appartement Amoz", 
                description: "Parce qu'il voulait un loft avec vue sur la mer",
                // picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
                picture: RiveDegier,
                date: "Novembre 2024"
            },
        ]
    }
]


const carrouselItemsMobile = [
    {
        title: "Dégats des eaux n°1", 
        description: "Un concept original la pluie dans la cuisine",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
        date: "Janvier 2024"
    },
    {
        title: "Prise des locaux", 
        description: "Notre aventure a démarrée au 160 avenue Jean Jaurès",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
        date: "Février 2024"
    },
    {
        title: "Voiture d'Amoz qui flambe", 
        description: "Et non, ce n'est ni une blague, ni une référence à Johnny",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
        date: "Mars 2024"
    },
    {
        title: "Retour du québécois", 
        description: "Fini les caribous et les tabernak, il est de retour",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Mai 2024"
    },
    {
        title: "Diplôme obtenu !", 
        description: "Eh oui, l'égo de l'ingénieur est encore plus grand",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
        date: "Juin 2024"
    },
    {
        title: "Essai 1 permis Maxime", 
        description: "Le premier d'une longue série...",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
        date: "Juin 2024"
    },
    {
        title: "Bilan de la société", 
        description: "Et les impôts aussi nous ont félicité",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Juillet 2024"
    },
    {
        title: "Nouvelle Audi dans la famille", 
        description: "18 000km autorisés, 60 000 déjà réalisés",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Juillet 2024"
    },
    {
        title: "Voyage au Puy du Fou", 
        description: "Avec nos amis Lucas et Céline",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Juillet 2024"
    },
    {
        title: "Essai 2 permis Maxime", 
        description: "Toujours de l'espoir...",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Août 2024"
    },
    {
        title: "Permis obtenu ! (bis)", 
        description: "Amoz a obtenu son permis ! Mais presque aussitôt...",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Août 2024"
    },
    {
        title: "Premier CDI signé", 
        description: "Premier CDI signé par un membre de l'équipe !",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
        date: "Septembre 2024"
    },
    {
        title: "CDI pour l'ingénieur", 
        description: "Et un CDI de signé, un !",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
        date: "Septembre 2024"
    },
    {
        title: "Permis obtenu !", 
        description: "Du premier coup, ou presque...",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
        date: "Septembre 2024"
    },
    {
        title: "Amoz fait un bisou à une voiture", 
        description: "Et oui, il a osé, et son poignet s'en souvient",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
        date: "Septembre 2024"
    },
    {
        title: "Vacances en Italie gratuit", 
        description: "+ week-end avec Amoz et son poignet",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
        date: "Septembre 2024"
    },
    {
        title: "Changement d'appartement", 
        description: "Et oui, parce qu'un ingénieur ça déménage",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Septembre 2024"
    },
    {
        title: "Passerelle moto de Quentin", 
        description: "Du premier coup ! (enfin !)",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Septembre 2024"
    },
    {
        title: "Dégats des eaux n°2", 
        description: "Parce que dans la cuisine ça ne suffisait pas, il nous fallait aussi la salle de bain",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
        date: "Septembre 2024"
    },
    {
        title: "Victoire au concours innovation Epitech", 
        description: "Parce qu'il n'y a pas que l'ingénieur qui réfléchit parfois",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
        date: "Octobre 2024"
    },
    {
        title: "CDI de Maxime", 
        description: "Parce qu'on n'avait pas assez d'un, il en fallait un deuxième",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/5.png?v=1602933990",
        date: "Octobre 2024"
    },
    {
        title: "Essai 3 permis Maxime", 
        description: "Et un, et deux, et trois zéro !",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/6.png?v=1602934064",
        date: "Novembre 2024"
    },
    {
        title: "Innondation appartement Amoz", 
        description: "Parce qu'il voulait un loft avec vue sur la mer",
        picture: "https://crystal-kitchen-ishi.myshopify.com/cdn/shop/articles/4.png?v=1602933933",
        date: "Novembre 2024"
    },
]

export default function News() {
    return (
        <div className="flex flex-col items-center justify-center font-bold px-4 py-12 md:py-12 md:px-40">
            <span
                className="text-[30px] mb-2 text-black text-center"
            >
                Les temps forts de cette année
            </span>
            <img src={Separator} />
            <Carousel
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                className="pb-12 relative mt-12"
                prevArrow={({ handlePrev }) => (
                    <div className="absolute bottom-4 right-1/2 flex flex-col gap-4 mr-2 h-8 w-8">
                        <FontAwesomeIcon icon="chevron-left" className="text-white bg-[#c12a26] rounded-full p-2" onClick={handlePrev} />
                    </div>
                )}
                nextArrow={({ handleNext }) => (
                    <div className="absolute bottom-4 left-1/2 flex flex-col gap-4 ml-2 h-8 w-8">
                        <FontAwesomeIcon icon="chevron-right" className="text-white bg-[#c12a26] rounded-full p-2" onClick={handleNext} />
                    </div>
                )}
                navigation={({ setActiveIndex, activeIndex, length }) => (
                    <></>
                )}
            >
                {window.innerWidth < 768 ? (
                    carrouselItemsMobile.map((product, index) => (
                        <Card 
                            key={index} 
                            card={product}
                        />
                    ))
                ) : carrouselItems.map((carrouselItem, index) => (
                    <div key={index} className="flex flex-col md:grid grid-cols-3 gap-12">
                        {carrouselItem.products.map((product, index) => (
                            <Card 
                                key={index} 
                                card={product}
                            />
                        ))}
                    </div>
                ))}
            </Carousel>
        </div>
    )
}