import BigChristmas from "../../../assets/pictures/big_christams.png"
import { CountDownChristams } from "../../../components/CountDownChristmas"

export default function Cards() {
    return (
        <div className="flex flex-col px-4 py-12 md:py-12 md:px-40 gap-6">
            <div className="md:h-96 bg-[#c12a26] rounded-lg shadow-md flex flex-col md:flex-row items-center justify-center gap-2 p-2">
                <div className="bg-white h-72 md:h-full w-full rounded">
                    <img src={BigChristmas} alt="Big Christmas" className="h-full w-full object-cover rounded" />
                </div>
                <div className="bg-[#c12a26] h-full w-full flex flex-col gap-3 justify-center items-center py-20 px-8">
                    <span className="text-center text-white text-4xl font-medium mb-4">Christmas Day</span>
                    {/* <span className="text-center text-white text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temopor incididunt utlabore</span> */}
                    <CountDownChristams circle={true} />
                </div>
            </div>
        </div>
    )
}