import Picture from "../../../assets/pictures/noel_header.webp"
import { CountDownChristams } from "../../../components/CountDownChristmas"

export default function Hero() {
    return (
        <div 
            className="h-screen flex flex-col justify-center items-center"
            style={{
                backgroundImage: `url(${Picture})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
        >
            <p className="text-lg text-red-600 uppercase">Christmas</p>
            <h1 className="text-4xl font-bold text-black mb-4">Noël 2024</h1>
            {/* <p className="text-lg text-black mb-4">Petit sous-titre tout mignon</p> */}
            <CountDownChristams />
        </div>
    )
}