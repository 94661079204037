import moment from "moment";
import { useEffect, useState } from "react";

interface CountDownChristmasProps {
    circle?: boolean; // Indique si le compte à rebours doit être affiché en cercle ou non
}
  
export const CountDownChristams: React.FC<CountDownChristmasProps>  = ({ circle = false }) => {
    const [timeLeft, setTimeLeft] = useState<string>("");
    const [seconds, setSeconds] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [days, setDays] = useState<number>(0);

    // Fonction pour calculer le temps restant
    const calculateTimeLeft = (): {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    } => {
        // Date limite : samedi 30 novembre 2024, 18h00 heure française
        const deadline = moment("24/12/2024 00:00", "DD/MM/YYYY HH:mm");
        const now = moment();
        // Temps restant en millisecondes
        const difference = deadline.diff(now);

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        // Calculer heures, minutes et secondes
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return { days, hours, minutes, seconds };
    };

    useEffect(() => {
        // Mettre à jour le décompte toutes les secondes
        const timer = setInterval(() => {
            const timeLeft = calculateTimeLeft();
            console.log(timeLeft)
            setDays(timeLeft.days);
            setSeconds(timeLeft.seconds);
            setMinutes(timeLeft.minutes);
            setHours(timeLeft.hours);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
            {circle ? (
                <>
                    <div className="h-20 w-20 flex flex-col justify-center items-center bg-white rounded-full text-sm leading-3">
                        <span className="text-2xl font-medium">
                            {days}
                        </span>
                        jours
                    </div>
                    <div className="h-20 w-20 flex flex-col justify-center items-center bg-white rounded-full text-sm leading-3">
                        <span className="text-2xl font-medium">
                            {hours}
                        </span>
                        heures
                    </div>
                    <div className="h-20 w-20 flex flex-col justify-center items-center bg-white rounded-full text-sm leading-3">
                        <span className="text-2xl font-medium">
                            {minutes}
                        </span>
                        min
                    </div>
                    <div className="h-20 w-20 flex flex-col justify-center items-center bg-black/80 text-white rounded-full text-sm leading-3">
                        <span className="text-2xl font-medium">
                            {seconds}
                        </span>
                        sec
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-col p-2 bg-red-500/20 text-[#2A323C] rounded-lg items-center justify-center w-20">
                        <span className="countdown font-mono text-5xl">
                        <span>{days}</span>
                        </span>
                        jours
                    </div>
                    <div className="flex flex-col p-2 bg-red-500/20 text-[#2A323C] rounded-lg items-center justify-center w-20">
                        <span className="countdown font-mono text-5xl">
                        <span>{hours}</span>
                        </span>
                        heures
                    </div>
                    <div className="flex flex-col p-2 bg-red-500/20 text-[#2A323C] rounded-lg items-center justify-center w-20">
                        <span className="countdown font-mono text-5xl">
                        <span>{minutes}</span>
                        </span>
                        min
                    </div>
                    <div className="flex flex-col p-2 bg-red-500/20 text-[#2A323C] rounded-lg items-center justify-center w-20">
                        <span className="countdown font-mono text-5xl">
                        <span>{seconds}</span>
                        </span>
                        sec
                    </div>
                </>
            )}
        </div>
    );
};
