import { useEffect, useState } from "react";

import Pikachu from "../../../assets/pictures/under-construction-pikachu.gif"
import Separator from "../../../assets/pictures/septor.png"
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CountdownTimer } from "../../../components/CountDownNight";

const Card = ({
    card
} : {
    card: {
        title: string,
        price: string,
        picture: string,
        link: string,
        kind: string
    }
}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div className="h-96 text-base" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="aspect-square h-80 relative bg-[#ccc]/10 flex items-center justify-center">
                {card.kind === "vroum" && (
                    <div className="absolute top-2 left-2 bg-yellow-700 rounded uppercase text-xs text-white py-1 px-2">
                        Vroum
                    </div>
                )}
                {isHovered && (
                    <div className="absolute right-2 top-2 flex flex-col gap-4">
                        <a 
                            href={card.link}
                            target="_blank"
                            className="bg-[#ccc] hover:bg-[#ccc]/70 transition-all text-white h-8 w-8 rounded-full flex items-center justify-center text-xs"
                        >
                            <FontAwesomeIcon icon="shopping-cart" />
                        </a>
                    </div>   
                )}
                <img src={card.picture} className="w-2/3" />
            </div>
            <div className="flex flex-col justify-center items-center h-16">
                <span className="font-medium">{card.title}</span>
                <span className="text-[#c12a26]">{card.price}</span>
            </div>
        </div>
    )
}

const products = [
    {
        title: "Ferrari rouge", 
        price: "(pas besoin de prix pour rêver)",
        picture: "https://cdn.ferrari.com/cms/network/media/img/resize/62542fdbbfaa93479b577c3e-ferrari-sf90-stradale-magazine-cover-desk-v2?width=1440&height=900",
        link: "https://www.ferrari.com/fr-FR/magazine/articles/a-very-special-red",
        kind: "vroum"
    },
    {
        title: "Panigale V2", 
        price: "(pas besoin de prix pour rêver)",
        picture: "https://images.ctfassets.net/x7j9qwvpvr5s/7uXQb25VEU5W35Zp3mVYeT/8f5a4e77c747874b3129304d153cab92/Panigale-V2-S-MY25-360_0017_it-18.png?w=1920&fm=webp&q=95",
        link: "https://www.ducati.com/fr/fr/motos/panigale/panigale-v2?gad_source=1&gclid=CjwKCAiAjKu6BhAMEiwAx4UsAngVHx2rTUt_FePvggsHZ3d2E-vRe19SS53CGu3kmqZcAQDQCkimhBoCUXgQAvD_BwE",
        kind: "vroum"
    },
    {
        title: "Audi RS etron GT", 
        price: "(pas besoin de prix pour rêver)",
        picture: "https://www.audi.fr/content/dam/nemo/fr/Gamme/e-tron-GT/RS-e-tron-GT/maj/1920x1080_RSetronGT_header_270624.jpg?width=1920",
        link: "https://www.audi.fr/fr/web/fr/gamme/e-tron-gt/audi-rs-e-tron-gt.html?&csref=sea:RMPHD:nc:[FR]-[PROD]-[E-TRON]-[GT]:C:GoogleAds:GoogleAds:p~GoogleAds:fn~Not_Used::mt~P:ai~&idform=4476&gclsrc=aw.ds&gad_source=1&gclid=CjwKCAiAjKu6BhAMEiwAx4UsAinLKTdcjv0hZYuHUXx6FVe3K_JbeqYzBJ4IBkucsY4gqrwwLvDrnRoCRrYQAvD_BwE&gclsrc=aw.ds",
        kind: "vroum"
    },
    {
        title: "RS660", 
        price: "(pas besoin de prix pour rêver)",
        picture: "https://wlassets.aprilia.com/wlassets/aprilia/master/Range/RS660/family_page/box/ApriliaRS660-Range/original/ApriliaRS660-Range.png?1726480103192",
        link: "https://www.aprilia.com/fr_FR/models/rs-660/",
        kind: "vroum"
    },
]

export default function SpecialDeals() {
    return (
        <div className="flex flex-col py-12 items-center justify-center font-bold">
            <span
                className="text-[30px] mb-2 text-black text-center"
            >
                Parce que tout le monde a le droit de rêver
            </span>
            <img src={Separator} />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-12">
                {products.map((product, index) => (
                    <Card 
                        key={index} 
                        card={product}
                    />
                ))}
            </div>
        </div>
    )
}