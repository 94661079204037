import React, { useRef, useEffect } from "react";

const FallingObjects: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext("2d")!;
    const objects: { x: number; y: number; size: number; speed: number; color: string }[] = [];
    const colors = ["#fff", "#fff", "#fff"];
    const numObjects = 50; // Nombre d'objets tombants

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Initialiser les objets
    for (let i = 0; i < numObjects; i++) {
      objects.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 5 + 2,
        speed: Math.random() * 2 + 0.5,
        color: colors[Math.floor(Math.random() * colors.length)],
      });
    }

    // Dessiner les objets
    const drawObjects = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Effacer le canvas
      objects.forEach((obj) => {
        ctx.beginPath();
        ctx.arc(obj.x, obj.y, obj.size, 0, Math.PI * 2);
        ctx.fillStyle = obj.color;
        ctx.fill();
        ctx.closePath();
      });
    };

    // Mettre à jour les objets
    const updateObjects = () => {
      objects.forEach((obj) => {
        obj.y += obj.speed; // Descendre
        if (obj.y > canvas.height) {
          obj.y = -obj.size; // Réinitialiser en haut
          obj.x = Math.random() * canvas.width; // Réinitialiser à une nouvelle position x
        }
      });
    };

    // Animation
    const animate = () => {
      drawObjects();
      updateObjects();
      requestAnimationFrame(animate);
    };

    animate();

    // Adapter le canvas à la taille de la fenêtre
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <canvas ref={canvasRef} className="block fixed top-0 left-0" />;
};

export default FallingObjects;
