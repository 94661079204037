import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RedBandeau from "../../../assets/pictures/bandeau_rouge.png"

export default function Section() {
    return (
        <div 
            className="h-96 w-full flex flex-col justify-center items-center"
            style={{
                backgroundImage: `url(${RedBandeau})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
        >
            <span   
                className="text-white"
                style={{
                    fontSize: "50px",
                    fontWeight: 700,
                    lineHeight: "50px",
                    marginBottom: "15px"
                }}
            >
                Introduction
            </span>
            <span
                className="text-sm text-white font-medium mb-3 text-center max-w-lg"
            >
                En cette période où l’esprit de Noël réchauffe nos cœurs, je voulais prendre un moment pour vous écrire. Cette fête si spéciale nous rappelle à quel point les liens qui nous unissent sont précieux. C’est l’occasion de célébrer l’amour, la joie et tous ces petits instants partagés qui font de notre famille un véritable cadeau.
            </span>
        </div>
    )
}