import moment from "moment";
import { useEffect, useState } from "react";

export const CountdownTimer: React.FC = () => {
    const [seconds, setSeconds] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);

    // Fonction pour calculer le temps restant
    const calculateTimeLeft = (): {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    } => {
        // Date limite : samedi 30 novembre 2024, 18h00 heure française
        const deadline = moment("01/12/2024 00:01", "DD/MM/YYYY HH:mm");
        const now = moment();
        // Temps restant en millisecondes
        const difference = deadline.diff(now);

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        // Calculer heures, minutes et secondes
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return { days, hours, minutes, seconds };
    };

    useEffect(() => {
        const timer = setInterval(() => {
            const timeLeft = calculateTimeLeft();
            setSeconds(timeLeft.seconds);
            setMinutes(timeLeft.minutes);
            setHours(timeLeft.hours);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="flex items-center justify-center gap-2">
            <div className="flex flex-col p-2 bg-red-500/20 text-[#2A323C] rounded-lg items-center justify-center w-20">
                <span className="countdown font-mono text-5xl">
                <span>{hours}</span>
                </span>
                heures
            </div>
            <div className="flex flex-col p-2 bg-red-500/20 text-[#2A323C] rounded-lg items-center justify-center w-20">
                <span className="countdown font-mono text-5xl">
                <span>{minutes}</span>
                </span>
                min
            </div>
            <div className="flex flex-col p-2 bg-red-500/20 text-[#2A323C] rounded-lg items-center justify-center w-20">
                <span className="countdown font-mono text-5xl">
                <span>{seconds}</span>
                </span>
                sec
            </div>
        </div>
    );
};
