import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Separator from "../../../assets/pictures/septor.png";
import Pikachu from "../../../assets/pictures/under-construction-pikachu.gif";
import { CountdownTimer } from "../../../components/CountDownNight";

import PikachuPresent from "../../../assets/pictures/presents/pikachu.jpg"
import PichetPresent from "../../../assets/pictures/presents/pichet.jpg"
import CakePresent from "../../../assets/pictures/presents/cake.jpg"
import DigitalPresent from "../../../assets/pictures/presents/digital.jpg"
import DouillePresent from "../../../assets/pictures/presents/douille.jpg"
import DouillesPresent from "../../../assets/pictures/presents/douilles.jpg"
import GenoisePresent from "../../../assets/pictures/presents/genoise.jpg"
import GrilleGateauPresent from "../../../assets/pictures/presents/grilleGateau.jpg"
import JamaisPresent from "../../../assets/pictures/presents/jamais.jpg"
import MadeleinePresent from "../../../assets/pictures/presents/madeleine.jpg"
import ManquerPresent from "../../../assets/pictures/presents/manquer.jpg"
import Kenwood1 from "../../../assets/pictures/presents/kenwood1.webp"
import Kenwood2 from "../../../assets/pictures/presents/kenwood2.webp"
import QuichePresent from "../../../assets/pictures/presents/quiche.jpg"
import SapinPresent from "../../../assets/pictures/presents/sapin.jpg"
import TransportPresent from "../../../assets/pictures/presents/transport.jpg"
import TransportCake from "../../../assets/pictures/presents/transportCake.jpg"
import TransportRectangle from "../../../assets/pictures/presents/transportRectangle.jpg"
import moment from "moment";

const Card = ({
    card
} : {
    card: {
        title: string,
        price: string,
        picture: any,
        link: string,
        kind: string
    }
}) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div className="h-96 text-base" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="aspect-square h-80 relative bg-[#ccc]/10 flex items-center justify-center">
                {card.kind === "pastry" && (
                    <div className="absolute top-2 left-2 bg-red-900 rounded uppercase text-xs text-white py-1 px-2">
                        Pâtisserie
                    </div>
                )}
                {card.kind === "machine" && (
                    <div className="absolute top-2 left-2 bg-green-900 rounded uppercase text-xs text-white py-1 px-2">
                        Machine
                    </div>
                )}
                {isHovered && (
                    <div className="absolute right-2 top-2 flex flex-col gap-4">
                        <a 
                            href={card.link}
                            target="_blank"
                            className="bg-[#ccc] hover:bg-[#ccc]/70 transition-all text-white h-8 w-8 rounded-full flex items-center justify-center text-xs"
                        >
                            <FontAwesomeIcon icon="shopping-cart" />
                        </a>
                    </div>   
                )}
                <img src={card.picture} className="w-2/3" />
            </div>
            <div className="flex flex-col justify-center items-center h-16">
                <span className="font-medium">{card.title}</span>
                <span className="text-[#c12a26]">{card.price}</span>
            </div>
        </div>
    )
}

const products = [
    {
        title: "Robot pâtissier KENWOOD KWL90.009SI", 
        price: "649,99",
        // picture: "https://image.darty.com/petit_electromenager/fait_maison/machine_pates/hu_hr2665_96_s2310317661037A_145438120.jpg",
        picture: Kenwood1,
        link: "https://www.boulanger.com/ref/1147785",
        kind: "machine"
    },
    {
        title: "Robot pâtissier KENWOOD KVL85.009SI", 
        price: "599,00",
        // picture: "https://image.darty.com/petit_electromenager/fait_maison/machine_pates/hu_hr2665_96_s2310317661037A_145438120.jpg",
        picture: Kenwood2,
        link: "https://www.boulanger.com/ref/1168079",
        kind: "machine"
    },
    {
        title: "Tapis à genoise", 
        price: "35,90",
        // picture: "https://boutique.guydemarle.com/11795-large_default/tapis-a-genoise-en-silicone-ohra.jpg",
        picture: GenoisePresent,
        link: "https://boutique.guydemarle.com/moules-ohra/4426-tapis-a-genoise-en-silicone-ohra.html?mot_tcid=78a3869c-2b92-4b8c-83a2-2da608d8ded6",
        kind: "pastry"
    },
    {
        title: "Moule à cake", 
        price: "35,90",
        // picture: "https://boutique.guydemarle.com/11587-large_default/moule-a-cake-ohra.jpg",
        picture: CakePresent,
        link: "https://boutique.guydemarle.com/moules-ohra/4364-moule-a-cake-ohra.html?mot_tcid=f3e38143-f6a6-4c7b-af40-92bc348fad59",
        kind: "pastry"
    },
    {
        title: "Jamais l'une sans l'autre", 
        price: "42,90",
        // picture: "https://boutique.guydemarle.com/16358-large_default/offre-toile-de-cuisson.jpg",
        picture: JamaisPresent,
        link: "https://boutique.guydemarle.com/toiles-et-tapis-de-cuisson/8278-offre-toile-de-cuisson.html?mot_tcid=50226b1b-c773-47a4-8001-071000a562ad",
        kind: "pastry"
    },
    {
        title: "Moule à madeleine", 
        price: "46,90",
        // picture: "https://boutique.guydemarle.com/12051-home_default/moule-a-madeleines-en-silicone.jpg",
        picture: MadeleinePresent,
        link: "https://boutique.guydemarle.com/moules-ohra/4512-moule-a-madeleines-en-silicone.html?mot_tcid=c5ffcf56-e333-4553-be58-533d101cd796",
        kind: "pastry"
    },
    {
        title: "Moule sapin", 
        price: "35,90",
        // picture: "https://boutique.guydemarle.com/12425-large_default/moule-sapin-3d-silicone.jpg",
        picture: SapinPresent,
        link: "https://boutique.guydemarle.com/moules-ohra/4630-moule-sapin-3d-silicone.html?mot_tcid=6a07c690-9a52-4d6c-81f9-a207b7d3e44e",
        kind: "pastry"
    },
    {
        title: "Pichet verseur", 
        price: "7,90",
        // picture: "https://boutique.guydemarle.com/2230-home_default/pichet-verseur-guy-demarle.jpg",
        picture: PichetPresent,
        link: "https://boutique.guydemarle.com/accessoires-de-cuisine/681-pichet-verseur-guy-demarle.html?mot_tcid=c588126b-c287-465a-9196-d113a988c24c",
        kind: "pastry"
    },
    {
        title: "Douilles", 
        price: "20,90",
        // picture: "https://boutique.guydemarle.com/2175-large_default/lot-de-4-douilles-guy-demarle.jpg",
        picture: DouillesPresent,
        link: "https://boutique.guydemarle.com/ustensiles-de-cuisine-patisserie/648-lot-de-4-douilles-guy-demarle.html?_gl=1*2pjjjl*_up*MQ..*_ga*NjU4ODQwODM3LjE3MzI5OTc2NjQ.*_ga_HDBS8M8G22*MTczMjk5NzY2My4xLjAuMTczMjk5NzY2My4wLjAuMA..*_ga_1L86TBGYR7*MTczMjk5NzY2My4xLjAuMTczMjk5NzY2My4wLjAuMA..",
        kind: "pastry"
    },
    {
        title: "Thermomètre digital", 
        price: "21,90",
        // picture: "https://boutique.guydemarle.com/2236-large_default/thermometre-digital-guy-demarle.jpg",
        picture: DigitalPresent,
        link: "https://boutique.guydemarle.com/accessoires-de-cuisine/686-thermometre-digital-guy-demarle.html?mot_tcid=37a0f7cf-5792-42f8-b733-a2b69e168c44",
        kind: "pastry"
    },
    {
        title: "Support poche à douille", 
        price: "18,90",
        // picture: "https://boutique.guydemarle.com/2213-large_default/support-poches-a-douilles-guy-demarle.jpg",
        picture: DouillePresent,
        link: "https://boutique.guydemarle.com/ustensiles-de-cuisine-patisserie/672-support-poches-a-douilles-guy-demarle.html?mot_tcid=e7c863ee-ba54-4bea-b8f4-faecb250d10d",
        kind: "pastry"
    },
    {
        title: "Emporte pièce pikachu", 
        price: "(pas de référence précise)",
        // picture: "https://m.media-amazon.com/images/I/71XxtvDIeNL._AC_SX679_.jpg",
        picture: PikachuPresent,
        link: "https://www.amazon.fr/gp/product/B0BHVH742B/ref=ox_sc_saved_image_1?smid=&psc=1",
        kind: "pastry"
    },
    {
        title: "Moule à quiche", 
        price: "(pas de référence précise)",
        // picture: "https://www.equipementpro.fr/85605-large_default/moule-a-quiche-250-mm-anti-adhesif-vogue.jpg",
        picture: QuichePresent,
        link: "",
        kind: "pastry"
    },
    {
        title: "Moule à manquer",
        price: "(pas de référence précise)",
        // picture: "https://content.pearl.fr/media/cache/default/article_ultralarge_high_nocrop/shared/images/articles/Z/ZX6/3-moules-a-manque-antiadhesifs-ref_ZX6075_7.jpg",
        picture: ManquerPresent,
        link: "",
        kind: "pastry"
    },
    {
        title: "Boîte de transport",
        price: "(pas de référence précise)",
        // picture: "https://m.media-amazon.com/images/I/71SjafsRpgL.__AC_SX300_SY300_QL70_ML2_.jpg",
        picture: TransportPresent,
        link: "",
        kind: "pastry"
    },
    {
        title: "Boîte de transport cake",
        price: "(pas de référence précise)",
        // picture: "https://m.media-amazon.com/images/I/91UIK6m20TL._AC_SX679_.jpg",
        picture: TransportCake,
        link: "",
        kind: "pastry"
    },
    {
        title: "Boîte de transport rectangle",
        price: "(pas de référence précise)",
        // picture: "https://m.media-amazon.com/images/I/71SPMBdjsRL._AC_SX679_.jpg",
        picture: TransportRectangle,
        link: "https://www.amazon.fr/Relaxdays-Plaque-Cuisson-avec-Couvercle/dp/B0CLRTP5D1/ref=sr_1_1_sspa?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3M9NT4MC7OP9Z&dib=eyJ2IjoiMSJ9.9Rxz77R_3zT_ajyisDvgmqGCNDEjh0_xQqMm7j2-k1V-h6xOmCFQ221FTz5seH3bQXNPPIirxxUn_z03oIXPqxGEAp1f3_XCPDRKbldW4aciR9yODNWoLR_SQpwEdSpF2Jhix_H8sqar0S56bSTHkyus4aNcbFtR1aFhIiGh6nWQ4fj-FzPvfy8yNC_4RWkJmzdmyYBocHQV7CFlB1WTa36s8Z-1KUnsKnRY2KHADz3BGZIFk9B3JD2mbBAvHtEYzdBjGXSsm-_vzm01lEIH1j0MONW49TjgfqXsZ0mip5U.1mbntYYP07YaP8UDFR3c0mMVrYMS30189IMADoplaoU&dib_tag=se&keywords=plaque%2Bde%2Bcuisson%2Bavec%2Bcouvercle&nsdOptOutParam=true&qid=1732999413&s=kitchen&sprefix=plaque%2Bde%2Bcuisson%2Bavec%2Bcouvercl%2Ckitchen%2C94&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        kind: "pastry"
    },
    {
        title: "Grille à gâteau",
        price: "(pas de référence précise)",
        // picture: "https://m.media-amazon.com/images/I/71E2v+yuhyL._AC_SY300_SX300_.jpg",
        picture: GrilleGateauPresent,
        link: "https://www.amazon.fr/Herogo-grilles-refroidissement-inoxydable-lave-vaisselle/dp/B09XT4RVKM/ref=sr_1_3?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3JURW96YFIMM2&dib=eyJ2IjoiMSJ9.poSpDn10RX15bNzIBnZ-TISh0bg0j0UONi2yhmqSVV54MG8eqhzuwFFFXO2AV8sDzOuQUmMwoF-v_skRO7k4TAweCz2xWmYJ5mZBVJEuclQqdo9Ig9dAje1urwvKOktHFsk5Ua7z3gqrS4eHNS8pWeEn8MJmcs-TxBVZOzr-DWQlbCZ1oVaReG0dSmA8NSnAct-QkwiNItlA4ULB6BNPOW5UhL6glcjViGRlSR8vmt8oJNasBMotBfRBD9FSNsBhiMHFnk5EIdPbsOMmGsP-uz43HO1cWKkLaqvL2LjPo4o.gqKgCLx8mHCVR0M08dQZhs16WUk-79Kj5amUF2re9-4&dib_tag=se&keywords=grille%2Ba%2Bgateau%2Bsur%2Bpied&nsdOptOutParam=true&qid=1732999683&s=kitchen&sprefix=grille%2Ba%2Bgateau%2Bsur%2Bpie%2Ckitchen%2C88&sr=1-3&th=1",
        kind: "pastry"
    }
]

export default function Listing() {
    const [isLoading, setIsLoading] = useState(true);
    const [isBeforeDeadline, setIsBeforeDeadline] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        
        const deadline = moment("2024-12-01 00:01");
        const now = moment();
        setIsBeforeDeadline(now.isBefore(deadline));
        
        setIsLoading(false);
    }, []);

    return (
        <div className="flex flex-col py-12 items-center justify-center font-bold">
            <span
                className="text-[30px] mb-2 text-black"
            >
                Notre liste de Noël
            </span>
            <img src={Separator} />
            {isLoading ? (
                <FontAwesomeIcon icon="spinner" className="animate-spin mt-8 h-6 text-[#c12a26]" />
            ) : isBeforeDeadline ? (
                <div className="flex flex-col items-center justify-center mt-12 gap-4">
                    <img src={Pikachu} />
                    <h1>En construction</h1>
                    <CountdownTimer />
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-12">
                    {products.map((product, index) => (
                        <Card 
                            key={index} 
                            card={product}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}